























































import { Component, Prop } from 'vue-property-decorator';
import { BModal, BvModalEvent } from 'bootstrap-vue';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import Submission from '@/models/experiment/Submission';
import SubmissionService from '@/services/SubmissionService';

@Component({})
export default class ChangeSubmissionModal extends mixins(Toasts) {
  @Prop({ required: false })
  protected submission!: Submission;
  @Prop({ required: true })
  protected uid!: string;
  @Prop({ required: true })
  protected taskId!: number;

  public show(): void {
    (this.$refs.modal as BModal).show();
  }

  public hide(): void {
    (this.$refs.modal as BModal).hide();
  }

  protected handleClose(e: BvModalEvent): void {
    this.$emit('close', e);
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  protected solveSubmission(): void {
    this.setLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (this.submission.id! >= 0) {
      this.submission.completed = true;
      SubmissionService.saveSubmission(this.submission).then(() => {
        this.setLoading(false);
        this.$emit('close');
      });
    } else {
      const sub = new Submission(this.uid, true, this.taskId);
      SubmissionService.saveSubmission(sub).then(() => {
        this.setLoading(false);
        this.$emit('close');
      });
    }
  }
  protected startSubmission(): void {
    this.setLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (this.submission.id! >= 0) {
      this.submission.completed = false;
      SubmissionService.saveSubmission(this.submission).then(() => {
        this.setLoading(false);
        this.$emit('close');
      });
    } else {
      const sub = new Submission(this.uid, false, this.taskId);
      SubmissionService.saveSubmission(sub).then(() => {
        this.setLoading(false);
        this.$emit('close');
      });
    }
  }

  protected unsolveSubmission(): void {
    this.setLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    SubmissionService.deleteSubmission(this.submission.id!).then(() => {
      this.setLoading(false);
      this.$emit('close');
    });
  }
}
