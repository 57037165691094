













import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import { Component } from 'vue-property-decorator';
import Experiment from '@/models/experiment/Experiment';
import ExperimentService from '@/services/ExperimentService';
import ExperimentSubmissionTable from '@/components/experiment/ExperimentSubmissionTable.vue';
import { hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';
@Component({
  components: { ExperimentSubmissionTable },
})
export default class ExperimentSubmissionOverview extends mixins(Toasts) {
  protected experiments: Experiment[] = [];
  protected breadcrumbs: { text: string; to?: string; active?: boolean }[] | null = null;
  mounted(): void {
    if (hasPermission(PossibleAction.CAN_NAVIGATE_ADMINISTRATION)) {
      this.breadcrumbs = [
        { text: 'Home', to: '/' },
        { text: 'Experiment Submissions', active: true },
      ];
      this.setLoading(true);
      this.getAllExperiments();
    } else {
      this.$router.replace({ path: '/' });
    }
  }

  protected getAllExperiments(): void {
    this.setLoading(true);
    ExperimentService.getAllExperimentsForUser()
      .then((result) => {
        this.experiments = result;
        this.setLoading(false);
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast(
            'Failed to load',
            'Could not load experiments. ' + backendError.response.data.message,
            'danger'
          );
        }
      });
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }
}
