





































































import Component, { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import Experiment from '@/models/experiment/Experiment';
import UserService from '@/services/UserService';
import UserInfo from '@/models/users/UserInfo';
import { Prop } from 'vue-property-decorator';
import TaskService from '@/services/TaskService';
import SuperTask from '@/models/experiment/SuperTask';
import { SubmissionEnum } from '@/models/experiment/SubmissionEnum';
import SubmissionService from '@/services/SubmissionService';
import Submission from '@/models/experiment/Submission';
import ReviewTask from '@/models/experiment/ReviewTask';
import Task from '@/models/experiment/Task';
import DrawableSvgComponent from '@/components/Editor/drawables/DrawableSvgComponent.vue';
import { TaskTypeEnum } from '@/models/experiment/TaskTypeEnum';
import ReviewService from '@/services/ReviewService';
import Review from '@/models/reviews/Review';
import ChangeSubmissionModal from '@/components/modals/Experiment/ChangeSubmissionModal.vue';
import { BModal, BvModal } from 'bootstrap-vue';

@Component({
  components: { ChangeSubmissionModal, DrawableSvgComponent },
})
export default class ExperimentSubmissionTable extends mixins(Toasts) {
  protected pageSize = 50;
  protected currentPageNo = 1;

  @Prop({ required: true })
  protected experiment!: Experiment;

  protected tasks: SuperTask[] = [];
  protected normalTasks: Task[] = [];
  protected reviewTasks: ReviewTask[] = [];
  protected participants: UserInfo[] = [];
  protected submissions: Submission[] = [];
  protected reviews: Review[] = [];
  protected isLoaded = false;

  protected modalSubmission: Submission = new Submission('', false, -1);
  protected modalTaskId = -1;
  protected modalUid = '';

  protected interval!: number;
  mounted(): void {
    this.loadData();
    this.interval = window.setInterval(() => {
      this.isLoaded = true;
      this.loadData();
      console.log('refresh');
    }, 60000);
  }

  destroyed(): void {
    console.log('Stopping interval.');
    clearInterval(this.interval);
  }

  protected loadData(): void {
    this.getAllTasksOfExperiment();
    this.getAllParticipantsForExperiment();
    this.getAllSubmissionsForExperiment();
  }

  protected getAllSubmissionsForExperiment(): void {
    SubmissionService.getAllSubmissionsOfExperiment(this.experiment.id ?? -1)
      .then((res) => {
        this.submissions = res;
      })
      .catch((backendError) => {
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast(
            'Failed to load',
            'Could not load submissions. ' + backendError.response.data.message,
            'danger'
          );
        }
      });
  }

  protected getAllTasksOfExperiment(): void {
    TaskService.getAllTasksForExperiment(this.experiment.id ?? -1)
      .then((res) => {
        this.tasks = res;
      })
      .catch((backendError) => {
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to load', 'Could not load tasks. ' + backendError.response.data.message, 'danger');
        }
      });

    TaskService.getAllNormalTasksForExperiment(this.experiment.id ?? -1)
      .then((result) => {
        this.normalTasks = result;
      })
      .catch((backendError) => {
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to load', 'Could not load tasks. ' + backendError.response.data.message, 'danger');
        }
      });

    TaskService.getAllReviewTasksForExperiment(this.experiment.id ?? -1)
      .then((result) => {
        this.reviewTasks = result;
        this.reviews = [];
        for (const t of this.reviewTasks) {
          this.getAllReviews(t.reviewAssignment);
        }
      })
      .catch((backendError) => {
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to load', 'Could not load tasks. ' + backendError.response.data.message, 'danger');
        }
      });
  }

  protected isPlaceholderTask(id: number): boolean {
    for (const t of this.normalTasks) {
      if (t.id === id && t.isPlaceHolder) {
        return true;
      }
    }
    return false;
  }

  protected getReportedReviewDefects(uid: string, taskid: number): number {
    for (const rt of this.reviewTasks) {
      if (rt.id === taskid) {
        for (const review of this.reviews) {
          if (review.userId === uid && review.assignmentId === rt.reviewAssignment) {
            return review.defectReports.length;
          }
        }
      }
    }
    return 0;
  }

  protected openModal(uid: string, taskid: number): void {
    this.modalUid = uid;
    this.modalTaskId = taskid;
    if (this.getSubmission(uid, taskid) == null) {
      this.modalSubmission = new Submission('', false, -1);
    } else {
      this.modalSubmission = this.getSubmission(uid, taskid) ?? new Submission('', false, -1);
    }

    (this.$refs['change-submission-modal-ref'] as BModal).show();
  }

  protected getSubmission(uid: string, taskid: number): Submission | null {
    for (let s of this.submissions) {
      if (s.username === uid && s.taskId === taskid) {
        return s;
      }
    }
    return null;
  }

  protected getSubmissionState(uid: string, taskid: number): SubmissionEnum {
    const submission = this.getSubmission(uid, taskid);
    if (submission !== null) {
      if (submission.completed) {
        return SubmissionEnum.SOLVED;
      } else {
        return SubmissionEnum.STARTED;
      }
    }
    return SubmissionEnum.UNSOLVED;
  }

  protected getTaskType(taskId: number): TaskTypeEnum {
    for (const t of this.normalTasks) {
      if (t.id == taskId) {
        return TaskTypeEnum.NORMAL;
      }
    }
    for (const t of this.reviewTasks) {
      if (t.id == taskId) {
        return TaskTypeEnum.REVIEW;
      }
    }
    return TaskTypeEnum.GOOGLEFORMS;
  }

  protected getAllReviews(reviewAssignmentId: number): void {
    ReviewService.getAllByAssignmentId(reviewAssignmentId).then((result) => {
      for (const r of result) {
        this.reviews.push(r);
      }
    });
  }

  protected getAllParticipantsForExperiment(): void {
    if (this.experiment.participants.length <= 0) {
      this.participants = [];
      return;
    }
    this.setLoading(true);
    UserService.getUserInfoByIdsArray(this.experiment.participants)
      .then((res) => {
        this.participants = res;
        this.setLoading(false);
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast(
            'Failed to load',
            'Could not load participants. ' + backendError.response.data.message,
            'danger'
          );
        }
      });
  }

  protected setLoading(loading: boolean): void {
    if (!this.isLoaded) {
      this.$root.$data.loading = loading;
    }
  }

  protected navigate(name: string): void {
    this.$router.push(name);
  }

  protected closeModal(): void {
    (this.$refs['change-submission-modal-ref'] as BModal).hide();
    this.loadData();
  }

  protected getTableHeaders(): unknown[] {
    const headers: unknown[] = [];
    headers.push({
      key: 'uid',
      label: 'User',
      sortable: true,
    });
    headers.push({
      key: 'firstName',
      label: 'Firstname',
      sortable: true,
    });
    headers.push({
      key: 'lastName',
      label: 'Lastname',
      sortable: true,
    });

    for (let t of this.tasks.filter((x) => !this.isPlaceholderTask(x.id ?? -1))) {
      headers.push({
        key: '' + t.id,
        label: '' + t.shortName,
        sortable: false,
      });
    }

    return headers;
  }
}
